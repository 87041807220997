<template>
  <Layout :top-bar-title="title" :sections="sections" :baseLayoutInfoUrl="'/base-layout-info/'">
    <ProductBrandForm
      :showHeader="false"
      @close="$router.push({name: 'ProductBrandList'})"
      />
  </Layout>
</template>

<script>
import Layout from '@/apps/core/components/Layout'
import sections from '../../dido/views/defaultNavigationDrawerSections'
import { mapActions } from 'vuex'
import ProductBrandForm from '../components/forms/ProductBrandForm'

export default {
  components: { ProductBrandForm, Layout },
  data () {
    return {
      title: 'Marca',
      sections: sections,
      productBrandId: this.$route.params.id

    }
  },
  mounted () {
    this.getItem(this.productBrandId)
  },
  methods: {
    ...mapActions('productBrands', ['getItem'])
  }
}
</script>
